<div class="partners">
  <section class="ts-background">
    <div class="partners_header header-inside-space pb-5">
      <div class="container">

        <div class="row align-items-start">
          <div class="col-md-6 col-12">
            <h1 class="partners_title">Parceiros</h1>
            <p class=" text-white">
              A seguir estão descritos os critérios e parâmetros de participação e pontuação, relativos a cada um dos
              parceiros e seus produtos indicados para a campanha.
            </p>
            <p class="text-white ">
              <strong>Campanha válida de outubro 2024 a agosto de 2025</strong>
            </p>

          </div>
          <div class="col-md-6 col-12">


            <a target="_blank" href="assets/files/Pontuacao-Parceiros-AdrenalinaVW.pdf"
               class="btn btn-secondary no-uppercase no-bold"><b>Download do Regulamento PDF</b></a>
            <br>

            <a *ngIf="handlerDetail()" class="btn btn-outline-white  no-uppercase no-bold mb-2 mb-4 mt-4" href="javascript:void('');"
               [routerLink]="'/parceiros/detalhe'">Detalhe do Parceiro</a>

          </div>
        </div>
      </div>
    </div>
  </section>

  <mat-tab-group>
    <mat-tab label="Interozone">
      <section class="interozone position-relative">
        <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
        <div class="container py-5 awards_secondary">
          <div class="col-sm-4 d-flex">
            <div class="d-flex logo-card">
              <img src="assets/images/partner/interozone.png" alt="maxi">
            </div>
          </div>
          <div class="row mt-4">


            <div class="col-12 col-sm-11 offset-sm-1 pt-5">
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="text-center" scope="col">CATEGORIA</th>
                  <th class="text-center" scope="col">OBJETIVO DE COMPRA</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">A</td>
                  <td class="text-center">R$ 15.000,00</td>
                </tr>
                <tr>
                  <td class="text-center">B</td>
                  <td class="text-center">R$ 12.000,00</td>
                </tr>
                <tr>
                  <td class="text-center">C</td>
                  <td class="text-center">R$ 8.000,00</td>
                </tr>
                <tr>
                  <td class="text-center">D</td>
                  <td class="text-center">R$ 4.000,00</td>
                </tr>
                </tbody>
              </table>

            </div>

            <div class="col-12 col-sm-11 offset-sm-1 my-4">
              <hr />
            </div>

            <!--<div class="col-sm-4">
              <span class="interozone_subtitle ">
                As concessionárias serão pontuadas na compra do produto Interzone participantes da campanhas.
              </span>
              <div class="interozone_card margin">
                <span>CARTÃO AR | APR-EQ8-002-2</span>
              </div>
              <span class="interozone_subtitle ">
                A pontuação mensal terá como base a compra por objetivo do produto Interozone divido pelas categorias A, B, C e D da campanha Adrenalina, conforme tabela:
              </span>
            </div>-->
            <div class="col-sm-11 offset-sm-1">
              <div class="table-responsive mt-2">
                <table class="table table-secondary table-partners">
                  <thead class="thead-dark">
                  <tr>
                    <th style="font-size: 25px" colspan="5"><strong>Volume de Cartões</strong></th>
                  </tr>
                  <tr>
                    <th class="text-center" scope="col">A</th>
                    <th class="text-center" scope="col">B</th>
                    <th class="text-center" scope="col">C</th>
                    <th class="text-center" scope="col">D</th>
                    <th class="text-center bg-g" scope="col">Pontuação Mensal</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-center">100</td>
                    <td class="text-center">80</td>
                    <td class="text-center">60</td>
                    <td class="text-center">40</td>
                    <td class="text-center bg-g">40</td>
                  </tr>
                  <tr>
                    <td class="text-center">120</td>
                    <td class="text-center">100</td>
                    <td class="text-center">80</td>
                    <td class="text-center">60</td>
                    <td class="text-center bg-g">60</td>
                  </tr>
                  <tr>
                    <td class="text-center">140</td>
                    <td class="text-center">120</td>
                    <td class="text-center">100</td>
                    <td class="text-center">80</td>
                    <td class="text-center bg-g">80</td>
                  </tr>
                  <tr>
                    <td class="text-center">160></td>
                    <td class="text-center">140></td>
                    <td class="text-center">120></td>
                    <td class="text-center">100></td>
                    <td class="text-center bg-g">100</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-sm-11 offset-sm-1">

              <h3 class="mt-4">QUÍMICOS (ACESSÓRIOS)</h3>

              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="text-secondary" scope="col">Percentual de Cumprimento do Objetivo</th>
                  <th class="text-center" scope="col">Pontuação</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="w-50 text-center">100% > acima</td>
                  <td class="table-valor text-center">100</td>
                </tr>
                <tr>
                  <td class="w-50 text-center">90% a 99,99%</td>
                  <td class="table-valor text-center">80</td>
                </tr>
                <tr>
                  <td class="w-50 text-center">85% a 89,99%</td>
                  <td class="table-valor text-center">60</td>
                </tr>
                <tr>
                  <td class="w-50 text-center">80% a 84,99%</td>
                  <td class="table-valor text-center">40</td>
                </tr>
                </tbody>
              </table>

            </div>

            <div class="col-sm-11 offset-sm-1">
              <h4 class="text-align-center mt-4">PRODUTOS INTEROZONE ELEGÍVEIS NA CAMAPANHA</h4>
              <table class="table">
                <thead class="thead-dark">
                <tr>
                  <th class="text-center" scope="col">CÓDIGO</th>
                  <th class="text-center" scope="col">DESCRIÇÃO</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">6EA 096 301 A</td>
                  <td class="text-center">ODORIZADOR ABERSENSE 100ml / 63g</td>
                </tr>
                <tr>
                  <td class="text-center">6EA 096 301 B</td>
                  <td class="text-center">LIMPEZA DA CAIXA EVAPORADORA 500ml</td>
                </tr>
                <tr>
                  <td class="text-center">6EA 096 311</td>
                  <td class="text-center">CRISTALIZADOR DE VIDROS 30ML</td>
                </tr>
                <tr>
                  <td class="text-center">6EA 096 301 D</td>
                  <td class="text-center">LIMPADOR DE DPF 300ml / 290g</td>
                </tr>
                <tr>
                  <td class="text-center">6EA 096 309</td>
                  <td class="text-center">PROTETOR DE MOTORES 65ML / 40G</td>
                </tr>
                <tr>
                  <td class="text-center">6EA 096 150 A</td>
                  <td class="text-center">ENCHE E VEDA PNEU 400ml / 260g</td>
                </tr>
                </tbody>
              </table>


              <table class="table mt-4">
                <thead class="thead-dark">
                <tr>
                  <th class="text-center" scope="col">CATEGORIA</th>
                  <th class="text-center" scope="col">OBJETIVO DE COMPRA</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">A</td>
                  <td class="text-center">R$ 15.000,00</td>
                </tr>
                <tr>
                  <td class="text-center"> B</td>
                  <td class="text-center">R$ 12.000,00</td>
                </tr>
                <tr>
                  <td class="text-center">C</td>
                  <td class="text-center">R$ 8.000,00</td>
                </tr>
                <tr>
                  <td class="text-center">D</td>
                  <td class="text-center">R$ 4.000,00</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </section>
    </mat-tab>
    <mat-tab label="Tirreno">
      <section class="tirreno position-relative">
        <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
        <img class="triangle-1" src="assets/images/partner/triangle2.png" alt="triangle">
        <img class="triangle-2" src="assets/images/partner/triangle.png" alt="triangle">
        <div class="container awards_secondary py-5">
          <div class="row" style="row-gap: 30px">
            <div class="col-12">
              <div class="col-sm-4 d-flex">
                <div class="d-flex logo-card">
                  <img src="assets/images/partner/tirreno.png" alt="maxi">
                </div>
              </div>
              <div class="row row-descritive">
                <div class="col-12 col-lg-6">
                 <span class="tirreno_subtitle text-primary">
                     As concessionárias serão pontuadas na compra dos produtos Tirreno participantes da campanha.
                 </span>
                </div>
              </div>
            </div>
            <div class="col-12 d-flex tirreno_description flex-column flex-lg-row">
              <!--<div class="col-lg-2">
                <span class="interozone_subtitle">Os pontos passam a ser atribuídos numa escala crescente a partir do cumprimento de 80% do objetivo Tirreno preestabelecido para cada concessionária, conforme tabela:</span>
              </div>-->
              <div class="col-lg-8">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th class="text-center" scope="col">% Meta</th>
                      <th class="text-center text-secondary" scope="col">PONTUAÇÃO</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-center">De 0 a 79,99%</td>
                      <td class="text-center ">0</td>
                    </tr>
                    <tr>
                      <td class="text-center">De 80 a 84,99%</td>
                      <td class="text-center ">40</td>
                    </tr>
                    <tr>
                      <td class="text-center">De 85 a 89,99%</td>
                      <td class="text-center ">60</td>
                    </tr>
                    <tr>
                      <td class="text-center">De 90 a 94,99%</td>
                      <td class="text-center ">140</td>
                    </tr>
                    <tr>
                      <td class="text-center">De 95 a 99,99%</td>
                      <td class="text-center ">160</td>
                    </tr>
                    <tr>
                      <td class="text-center">100%</td>
                      <td class="text-center ">180</td>
                    </tr>
                    <tr>
                      <td class="text-center bg-secondary"><strong>Bônus (*)</strong></td>
                      <td class=" text-center bg-secondary">20</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-lg-4 tirreno_category">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th class="text-center" scope="col">Categoria</th>
                      <th class="text-center" scope="col">Objetivos de Compras</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-center">A</td>
                      <td class="text-center ">R$ 20.000,00</td>
                    </tr>
                    <tr>
                      <td class="text-center">B</td>
                      <td class="text-center ">R$ 14.000,00</td>
                    </tr>
                    <tr>
                      <td class="text-center">C</td>
                      <td class="text-center ">R$ 10.000</td>
                    </tr>
                    <tr>
                      <td class="text-center">D</td>
                      <td class="text-center ">R$ 6.000,00</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <span>(*) Bônus: Para o cliente ganhar a pontuação bônus deverá conter obrigatoriamente
em seu pedido o produto:
1) Fluido para freio DOT 4LV 500ml (B 000 750 Q2) e Aditivo de Radiador (G 12E 040
R2), independente do % da meta que ele comprar.
2) No decorrer da Campanha poderemos fazer mudanças no mix de produtos e
pontuações adicionais. </span>
              </div>
            </div>
            <div class="col-12 tirreno_campaign">
              <div class="container">
                <div class="column bg-gray">
                  <div class="w-100 d-flex justify-content-center align-items-center mb-4">
                    <h2 class="text-primary"><strong>Produtos avaliados na Campanha</strong></h2>
                  </div>
                  <div class="w-100 d-flex justify-content-center align-items-center">
                    <div class="w-100 mx-auto" style="max-width: 780px">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-dark">
                          <tr>
                            <th class="w-30 text-secondary" scope="col">CÓDIGO</th>
                            <th class="w-70" scope="col">DESCRIÇÃO</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td class="w-30">G 12E 040 R2</td>
                            <td>Aditivo de Radiador pronto uso G12evo 1L</td>
                          </tr>
                          <tr>
                            <td class="w-30">G JZZ 040 R3</td>
                            <td>Aditivo de Radiador pronto uso G12evo 5L</td>
                          </tr>
                          <tr>
                            <td class="w-30">B 000 750 Q2</td>
                            <td>Fluido para Freio DOT 4 LV 500ml</td>
                          </tr>
                          <tr>
                            <td class="w-30">G 052 164 R1</td>
                            <td>Limpa para-brisas 200ml</td>
                          </tr>
                          <tr>
                            <td class="w-30">B JZZ 700 Q2</td>
                            <td>Fluido para Freio DOT 4 500ml</td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </mat-tab>
    <mat-tab label="Maxi">
      <section class="maxi">
        <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
        <img class="triangle-2" src="assets/images/partner/triangle.png" alt="triangle">
        <img class="triangle-1" src="assets/images/partner/triangle2.png" alt="triangle">
        <div class="container awards_secondary py-5">
          <div class="row ">
            <div class="col-sm-4 d-flex">
              <div class="d-flex logo-card">
                <img src="assets/images/partner/maxi.png" alt="maxi">
              </div>
            </div>
          </div>
          <div class="col-lg-12 row-descritive">
            <div class="blue-card maxi">
              <div class="row w-100 justify-content-between">
                <div class="col-lg-5 bg-campaign">
                  <p>A pontuação mensal terá como base o percentual comparativo entre o volume, em litros, dos pedidos
                    colocados no mês e o volume mensal objetivado.</p>
                  <p>Para se ter elegibilidade da pontuação, o grupo econômico deve bater o alvo de óleo de motor;</p>
                  <p>Nota: são considerados na campanhas todos os óleos comercializados pela Volkswagen (litro ou
                    tambor/Maxi Performance).</p>
                </div>
                <div class="col-lg-6 ">
                  <p class="interozone_subtitle no-margin">A pontuação de cumprimento do objetivo será de acordo com
                    a seguinte escala</p>
                  <div class="table-responsive maxi">
                    <table class="table">
                      <thead class="thead-dark">
                      <tr>
                        <th class="text-secondary" scope="col">Percentual de Cumprimento do Objetivo</th>
                        <th class="text-center" scope="col">Pontuação</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="w-50 text-center">De 100% a 109,99%</td>
                        <td class="table-valor text-center">100</td>
                      </tr>
                      <tr>
                        <td class="w-50 text-center">De 110% a 119,99%</td>
                        <td class="table-valor text-center">150</td>
                      </tr>
                      <tr>
                        <td class="w-50 text-center">Acima de 120%</td>
                        <td class="table-valor text-center">200</td>
                      </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 tirreno_campaign">
            <div class="container">
              <div class="column bg-gray">
                <div class="w-100 d-flex justify-content-center align-items-center mb-4">
                  <h2 class="text-primary"><strong>Produtos avaliados na Campanha</strong></h2>
                </div>
                <div class="w-100 d-flex justify-content-center align-items-center">
                  <div class="w-100 mx-auto" style="max-width: 780px">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class="thead-dark">
                        <tr>
                          <th class="w-30 text-secondary" scope="col">APLICAÇÃO</th>
                          <th class="w-35 text-secondary" scope="col">SKU VW</th>
                          <th class="w-35 text-secondary" scope="col">DESCRIÇÃO</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="w-30">Oficina</td>
                          <td class="w-35">G-S555-167-R7-BRA</td>
                          <td class="w-35">Max performance 0W-30 502 505 CX 12X1L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Oficina</td>
                          <td class="w-35">G-S555-545-M2-BRA</td>
                          <td class="w-35">Max performance 0W-30 507 CX 12X1L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Oficina</td>
                          <td class="w-35">G-S555-545-M9-BRA</td>
                          <td class="w-35">Max performance 0W-30 507 TB 209L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Oficina</td>
                          <td class="w-35">G-S555-553-R2-BRA</td>
                          <td class="w-35">Max performance 5W-40 508.88 509.99 CX 12X1L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Oficina</td>
                          <td class="w-35">G-S555-553-R6-BRA</td>
                          <td class="w-35">Max performance 5W-40 508.88 509.99 TB 209L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Oficina</td>
                          <td class="w-35">G-198-553</td>
                          <td class="w-35">Max performance 5W-40 508.88 509.99 C TB 209L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Oficina</td>
                          <td class="w-35">G-S60-577-M2-BRA</td>
                          <td class="w-35">Max performance 0W-20 508 509 12X1L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Economy</td>
                          <td class="w-35">G-JZZ-020-C2-BRA</td>
                          <td class="w-35">Max performance 0W-20 ECONOMY TB 209L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Economy</td>
                          <td class="w-35">G-JZZ-020-R9-BRA</td>
                          <td class="w-35">Max performance 0W-20 ECONOMY CX 12X1L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Economy</td>
                          <td class="w-35">G-JZZ-250-R2-BRA</td>
                          <td class="w-35">Max performance 20W-50 SL CX12X1L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Economy</td>
                          <td class="w-35">G-JZZ-107-M2-BRA</td>
                          <td class="w-35">Max performance 10W-40 SP 505 CX 12X1L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Economy</td>
                          <td class="w-35">G-JZZ-530-R9-BRA</td>
                          <td class="w-35">Max performance 5W-30 SN GF-5 TB 209L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Economy</td>
                          <td class="w-35">G-JZZ-502-M9-BRA</td>
                          <td class="w-35">Max performance 5W-30 SP GF-6 CX 209L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Economy</td>
                          <td class="w-35">G-JZZ-530-Q2-BRA</td>
                          <td class="w-35">Max performance 5W-30 GF-6 CX 12X1L</td>
                        </tr>
                        <tr>
                          <td class="w-30">Economy</td>
                          <td class="w-35">G-JZZ-107-R2-BRA</td>
                          <td class="w-35">Max performance 10W-40 SP 501 505 12X1L</td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </mat-tab>
    <mat-tab label="Wurth">
      <section class="wurth">
        <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
        <img class="triangle-1" src="assets/images/partner/triangle2.png" alt="triangle">
        <div class="container awards_secondary py-5">
          <div class="row ">
            <div class="col-sm-4 d-flex">
              <div class="d-flex logo-card">
                <img src="assets/images/partner/wurth.png" alt="maxi">
              </div>
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-lg-6 col-12 pt-5">
              <p>
                As concessionárias serão pontuadas nas compras dos produtos WURTH cadastrados na campanha.
              </p>
              <p>
                <strong>*A lista de produtos será revisada mensalmente.</strong>
              </p>
              <p>
                Os objetivos individuais das concessionárias estarão disponíveis no site da campanha.
              </p>
              <p>
                A pontuação mensal será determinada baseada no atingimento do objetivo de acordo com a
                tabela.
              </p>
            </div>
            <div class="col-lg-5 col-12 mb-4 pt-md-5">
              <p>
                3. PONTUAÇÃO WURTH POWER<br>
                Comprando os itens HSW PRO3 80 ML e CAIXA EVAPORADORA 250 ml ganha pontuação de 30 pontos.
              </p>
              <p>
                4. PONTUAÇÃO PACOTE FIDELIDADE<br>
                Comprando o mix de produtos abaixo, mínimo de 1 caixa por produto, ganhe 30 pontos.
              </p>
              <span class="tirreno_subtitle text-primary">
                Para clientes novos ou sem histórico de compras WURTH durante o ano passado os objetivos são estabelecidos do acordo com a categorização VW de acordo com a seguinte tabela:
              </span>
            </div>
          </div>


          <div class="row pt-4">
            <div class="col-lg-6">
                <p class="text-center campaign_description_secondary mt-3"><strong>Objetivo mensal (R$) x
                  Pontuação</strong>
                </p>
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th class="table-title" scope="col">Categoria</th>
                      <th class="text-secondary text-center" scope="col">80 Pontos</th>
                      <th class="text-secondary text-center" scope="col">100 Pontos</th>
                      <th class="text-secondary text-center" scope="col">120 Pontos</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-center">A</td>
                      <td class="text-center table-valor">R$ 5.000,00</td>
                      <td class="text-center table-valor">R$ 10.000,00</td>
                      <td class="text-center table-valor">R$ 15.000,00</td>
                    </tr>
                    <tr>
                      <td class="text-center">B</td>
                      <td class="text-center table-valor">R$ 4.000,00</td>
                      <td class="text-center table-valor">R$ 7.500,00</td>
                      <td class="text-center table-valor">R$ 11.000,00</td>
                    </tr>
                    <tr>
                      <td class="text-center">C</td>
                      <td class="text-center table-valor">R$ 3.000,00</td>
                      <td class="text-center table-valor">R$ 5.500,00</td>
                      <td class="text-center table-valor">R$ 7.500,00</td>
                    </tr>
                    <tr>
                      <td class="text-center">D</td>
                      <td class="text-center table-valor">R$ 2.000,00</td>
                      <td class="text-center table-valor">R$ 3.000,00</td>
                      <td class="text-center table-valor">R$ 4.000,00</td>
                    </tr>
                    </tbody>
                  </table>

                </div>
        </div>
            <div class="col-lg-4">
              <span>
                A apuração do cumprimento do objetivo será de acordo com a escala abaixo:
              </span>

              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-dark">
                  <tr>
                    <th class="w-70 text-secondary" scope="col">Percentual de cumprimento do objetivo</th>
                    <th class="text-primary text-center w-30" scope="col">Pontuação</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-center w-70">0% a 79,99%</td>
                    <td class="text-center w-30">0</td>
                  </tr>
                  <tr>
                    <td class="text-center w-70">80% a 89,99%</td>
                    <td class="text-center w-30">50</td>
                  </tr>
                  <tr>
                    <td class="text-center w-70">90% a 99,99%</td>
                    <td class="text-center w-30">80</td>
                  </tr>
                  <tr>
                    <td class="text-center w-70">>= 100%</td>
                    <td class="text-center w-30">120</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <!--<div class="d-flex w-100 justify-content-between card-container">
            <div class="d-flex card-partners">
              <p class="card-partners_bonus">BÔNUS EXTRA<br>MENSAL</p>
            </div>
            <div class="card-partners_points">
              <p><strong>20</strong> <br>PONTOS</p>
            </div>
            <div class="card-partners_descritive">
              <p>
                Todos clientes que colocarem pedidos DSH de uma quantidade mínima de cada um dos produtos abaixo (*) da
                Wurth durante o mês, terão direito a uma pontuação extra de 20 pontos.
              </p>
            </div>
          </div>
          <div class="container" style="margin: 80px 0 60px">
            <p class="text-primary"> OBS: É necessário a compra de TODOS os produtos marcados abaixo (*) dentro do mesmo
              mês para garantir esse BONUS EXTRA MENSAL durante o período da Campanha ADRENALINA, sendo que para o
              produto DESCARBONIZANTE PRO a concessionária pode optar pela compra de uma das duas embalagens disponíveis
              (300 ou 65 ml) .”</p>
          </div>
          <div class="container">
            <div class="column bg-gray">
              <div class="w-100 d-flex justify-content-center align-items-center mb-4">
                <h2 class="text-primary"><strong>Produtos avaliados na Campanha</strong></h2>
              </div>
              <div class="w-100 d-flex justify-content-center align-items-center">
                <div class="w-50">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-dark">
                      <tr>
                        <th class="w-30 text-secondary " scope="col">Código</th>
                        <th style="text-transform: unset" class="w-70  " scope="col">Descrição
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="w-30 ">G 12E 040 R2</td>
                        <td>Aditivo de Radiador pronto uso G12evo 1L</td>
                      </tr>

                      <tr>
                        <td class="w-30 ">G JZZ 040 R3</td>
                        <td>Aditivo de Radiador pronto uso G12evo 5L</td>
                      </tr>
                      <tr>
                        <td class="w-30 ">B 000 750 Q2</td>
                        <td>Fluido para Freio DOT 4 LV 500ml</td>
                      </tr>
                      <tr>
                        <td class="w-30 ">G 052 164 R1</td>
                        <td>Limpa para-brisas 200ml</td>
                      </tr>
                      <tr>
                        <td class="w-30 ">B JZZ 700 Q2</td>
                        <td>Fluido para freio DOT 4 500ml</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </section>
    </mat-tab>
    <!--<mat-tab label="Petroplus">
      <section class="petropulus position-relative">
        <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
        <div class="container ">
          <div class="d-block">
            <div class="pt-5 col-12">

                <img class="d-flex logo-card" src="assets/images/petropolus.png" alt="" style="object-fit: contain">

            <div class="row">
              <div class="mt-5 col-lg-4">

                <p class="home_text">
                  Os objetivos das concessionárias são individuais e estarão disponíveis no site da campanha.
                  <br>
                  <br>
                  1  Serão atribuídos pontos escalonados de acordo com a porcentagem de atingimento do Objetivo Mensal
                  em
                  Pedidos colocados, podendo alcançar até 150 pontos.

                  <br>
                  <br>
                  2  Os 50 pontos restantes devem ser atribuídos apenas para Distribuidores Nacionais (DN) que atingirem
                  100% do objetivo proposto, sendo que, desse total, 50% deve ser no Item G-001-780-M3.
                  <br>
                  <br>
                </p>

                <div>
                  <p>
                    <b>Exemplo</b>
                    <br>
                    Se o objetivo mensal for de R$ 5.000,00,
                    o DN precisa alcançar os R$ 5.000,00,
                    sendo que R$ 2.500,00 devem ser do item G-001-780-M3.
                  </p>
                </div>


              </div>
              <div class="mt-5 offset-lg-2 col-lg-5">

                <p class="campaign_description_secondary my-3"><strong>
                  Segue abaixo o quadro
                  com o escalonamento dos pontos:
                </strong></p>
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th class=" w-70 text-secondary" scope="col">Percentual de cumprimento do objetivo</th>
                      <th class="text-primary text-center w-30" scope="col">Pontuação</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-primary text-center w-70">De 0,00% a 84,99%</td>
                      <td class=" bg-g text-center w-30">0</td>
                    </tr>
                    <tr>
                      <td class=" text-center w-70">85% a 89,99%</td>
                      <td class=" bg-g text-center w-30">40</td>
                    </tr>
                    <tr>
                      <td class="text-center w-70">90% a 94,99%</td>
                      <td class=" bg-g text-center w-30">80</td>
                    </tr>
                    <tr>
                      <td class="text-center w-70">95% a 99,99%</td>
                      <td class="bg-g text-center w-30">110</td>
                    </tr>
                    <tr>
                      <td class="text-center w-70">100% acima</td>
                      <td class=" bg-g text-center w-30">150</td>
                    </tr>
                    <tr class="bg-secondary">
                      <td class="bg-secondary text-primary w-70">50% do Objetivo Fat. em
                        G-001-780 - Pontuação
                        Adicional
                      </td>
                      <td class="bg-secondary text-primary text-center w-30">50</td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
            </div>


            <div class="container mt-5">
              <div class="column bg-gray">
                <div class="w-100 d-flex justify-content-center align-items-center mb-4 flex-column">
                  <h2 class="text-primary px-5"><strong>Produtos avaliados na Campanha</strong></h2>
                </div>
                <div class="w-100 d-flex justify-content-center align-items-center">
                  <div class="w-50">
                    <div class="table-responsive">
                      <table class="table">
                        <thead class="thead-dark">
                        <tr>
                          <th class="w-30 text-secondary " scope="col">Código</th>
                          <th style="text-transform: unset" class="w-70  " scope="col">Descrição
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>APR-096-153</td>
                          <td>Espuma de Limpeza à seco</td>
                        </tr>

                        <tr>
                          <td>G-001-780-M3</td>
                          <td>New AW-AG2000 - Aditivo de Combustível - Flex</td>
                        </tr>
                        <tr>
                          <td>G-JZW-012-R2</td>
                          <td>Aditivo - Líquido de Refrigeração</td>
                        </tr>
                        <tr>
                          <td>STP-ST0-912</td>
                          <td>Throttle Body & Air Intake Cleaner</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </mat-tab>-->
    <mat-tab label="Brazzo">
      <section class="position-relative bg-white">
        <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
        <img class="triangle-1" src="assets/images/partner/triangle2.png" alt="triangle">
        <img class="triangle-2" src="assets/images/partner/triangle.png" alt="triangle">
        <div class="container">
          <div class="d-block">
            <div class="row">
              <div class="mt-5 col-12 mb-5">
                <div class="d-flex logo-card">
                  <img src="assets/images/brazzo-lg.png" alt="Brazzo"/>
                </div>

                <h3 class="mt-5">OBJETIVO QUIMICOS</h3>
                <div class="row mt-5">
                  <div class="table-wrapper py-2">
                    <div class="table-responsive mt-5">
                      <table class="table">
                        <tbody>
                        <tr>
                          <td class="text-primary text-center" valign="top"><strong>CATEGORIA</strong></td>
                          <td class="text-center" valign="top"><strong>OBJETIVO MENSAL</strong></td>
                        </tr>
                        <tr>
                          <td class="text-primary text-center">A</td>
                          <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 10.000,00</strong></td>
                        </tr>
                        <tr>
                          <td class="text-primary text-center">B</td>
                          <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 8.000,00</strong></td>
                        </tr>
                        <tr>
                          <td class="text-primary text-center">C</td>
                          <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 6.000,00</strong></td>
                        </tr>
                        <tr>
                          <td class="text-primary text-center">D</td>
                          <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 4.000,00</strong></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="points-info">
                    <div class="points-card">
                      <p>100%</p>
                      <h3 class="points-total">40</h3>
                      <h4>Pontos</h4>
                    </div>
                    <div class="points-card">
                      <p>110%</p>
                      <h3 class="points-total">60</h3>
                      <h4>Pontos</h4>
                    </div>
                    <div class="points-card">
                      <p>120%</p>
                      <h3 class="points-total">100</h3>
                      <h4>Pontos</h4>
                    </div>
                  </div>
                </div>

                <!-- -->

                <h3 class="mt-4">OBJETIVO ACESSÓRIOS (PET, FRISOS E CADEIRINHA)</h3>

                <div class="row my-5">
                  <div class="table-wrapper py-2">
                    <div class="table-responsive mt-md-5">
                      <table class="table">
                        <tbody>
                        <tr>
                          <td class="text-primary text-center" valign="top"><strong>CATEGORIA</strong></td>
                          <td class="text-center" valign="top"><strong>OBJETIVO MENSAL</strong></td>
                        </tr>
                        <tr>
                          <td class="text-primary text-center">A</td>
                          <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 8.000,00</strong></td>
                        </tr>
                        <tr>
                          <td class="text-primary text-center">B</td>
                          <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 6.000,00</strong></td>
                        </tr>
                        <tr>
                          <td class="text-primary text-center">C</td>
                          <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 4.000,00</strong></td>
                        </tr>
                        <tr>
                          <td class="text-primary text-center">D</td>
                          <td class="table-valor text-center font-weight-bold bg-none"><strong>R$ 2.000,00</strong></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="points-info">
                    <div class="points-card">
                      <p>100%</p>
                      <h3 class="points-total">40</h3>
                      <h4>Pontos</h4>
                    </div>
                    <div class="points-card">
                      <p>110%</p>
                      <h3 class="points-total">60</h3>
                      <h4>Pontos</h4>
                    </div>
                    <div class="points-card">
                      <p>120%</p>
                      <h3 class="points-total">100</h3>
                      <h4>Pontos</h4>
                    </div>
                  </div>
                </div>

                <table class="table my-5">
                  <thead class="thead-dark bg-primary">
                  <tr>
                    <th class="text-center text-white" scope="col">APLICAÇÃO</th>
                    <th class="text-center text-white" scope="col">SKU VW</th>
                    <th class="text-center text-white" scope="col">DESCRIÇÃO</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA-096-301-C</td>
                    <td class="text-center">LIMPEZA DA CAIXA EVAPORADORA - 500ML</td>
                  </tr>
                  <tr>
                    <td class="text-center"> Químicos</td>
                    <td class="text-center">6EA-096-301-E</td>
                    <td class="text-center">LIMPA DPF - 500ML/407G</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA-096-306-A</td>
                    <td class="text-center">DESENGRIPANTE - 100ML/70G</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA-096-306-C</td>
                    <td class="text-center">GRAXA LÍQUIDA - 100ML/60G</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA-096-306-E</td>
                    <td class="text-center">SILICONE - 100ML/53G</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA-096-309-A</td>
                    <td class="text-center">PROTETOR PARA MOTORES - 200ML/100G</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA-096-315-A</td>
                    <td class="text-center">LAVAGEM A SECO - 500ML</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA-096-329-A</td>
                    <td class="text-center">ADITIVO COMBUSTÍVEL FLEX - 200ML</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA-096-329-B</td>
                    <td class="text-center">ADITIVO COMBUSTÍVEL GASOLINA - 90ML</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA 096 320</td>
                    <td class="text-center">FLUÍDO RADIADOR G12 EVO - 1L</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">6EA 096 320 B</td>
                    <td class="text-center">FLUÍDO RADIADOR G12 EVO - 5L</td>
                  </tr>
                  <tr>
                    <td class="text-center">Químicos</td>
                    <td class="text-center">G-600-200-R2-BR1</td>
                    <td class="text-center">SOLUÇÃO PARA LIMPEZA DE BICOS E BOMBAS DE ALTA</td>
                  </tr>
                  </tbody>
                </table>

                <table class="table">
                  <thead class="thead-dark bg-primary">
                    <tr>
                      <th class="text-center text-white" scope="col">APLICAÇÃO</th>
                      <th class="text-center text-white" scope="col">SKU VW</th>
                      <th class="text-center text-white" scope="col">DESCRIÇÃO</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-024-C</td>
                    <td class="text-center">ASSENTO DE TRANSPORTE PEQUENO</td>
                  </tr>
                  <tr>
                    <td class="text-center"> Acessórios</td>
                    <td class="text-center">V04-010-024-D</td>
                    <td class="text-center">ASSENTO DE TRANSPORTE GRANDE</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-024-E</td>
                    <td class="text-center">BOLSA DE TRANSPORTE</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-024-F</td>
                    <td class="text-center">CAIXA DE TRANSPORTE Nº 01</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-024-G</td>
                    <td class="text-center">CAIXA DE TRANSPORTE Nº 02</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-024-H</td>
                    <td class="text-center">CAIXA DE TRANSPORTE Nº 03</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-025-H</td>
                    <td class="text-center">ADAPTADOR DE CINTO DE SEGURANÇA (P)</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-025-J</td>
                    <td class="text-center">ADAPTADOR DE CINTO DE SEGURANÇA (G)</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-025-K</td>
                    <td class="text-center">COLEIRA PEITORAL</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-026-A</td>
                    <td class="text-center">PROTETOR DE BANCO BASIC</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-026-B</td>
                    <td class="text-center">PROTETOR DE BANCO STANDARD</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-026-C</td>
                    <td class="text-center">PROTETOR DE BANCO PREMIUM</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-029-K</td>
                    <td class="text-center">REDE PROTEÇÃO (P)</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-029-L</td>
                    <td class="text-center">REDE DE PROTEÇÃO COM BOLSOS</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-029-M</td>
                    <td class="text-center">REDE PROTEÇÃO (G)</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-AB-B6Q</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- CINZA OLIVER</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-AB-B7S</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- PRATA PYRIT</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-AB-C9A</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- BRANCO PURO</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-AB-C9X</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- PRETO MYSTIC</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-AB-P5C</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- AZUL ATLANTIC</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-AB-R7H</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- CINZA INDIUM</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-L-041</td>
                    <td class="text-center">FRISO BX RELEV TCROSS -- PRETO NINJA</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-L-A3X</td>
                    <td class="text-center">FRISO BX RELEV TCROSS -- VERMELHO SUNSET</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-L-A7A</td>
                    <td class="text-center">FRISO BX RELEV TCROSS -- CINZA ASCOT</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-L-B7S</td>
                    <td class="text-center">FRISO BX RELEV TCROSS -- PRATA PYRIT</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-L-C9A</td>
                    <td class="text-center">FRISO BX RELEV TCROSS -- BRANCO PURO</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-L-D7X</td>
                    <td class="text-center">FRISO BX RELEV TCROSS -- CINZA PLATINUM</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-L-E5M</td>
                    <td class="text-center">FRISO BX RELEV TCROSS -- AZUL NORWAY</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-M-041 </td>
                    <td class="text-center">FRISO BX RELEV VIRTUS -- PRETO NINJA </td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-M-B9A</td>
                    <td class="text-center">FRISO BX RELEV VIRTUS -- BRANCO CRISTAL</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-M-C5C</td>
                    <td class="text-center">FRISO BX RELEV VIRTUS -- AZUL BISCAY</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-M-D7X</td>
                    <td class="text-center">FRISO BX RELEV VIRTUS -- CINZA PLATINUM</td>
                  </tr>
                  </tbody>
                </table>

                <table class="table my-5">
                  <thead class="thead-dark bg-primary">
                  <tr>
                    <th class="text-center text-white" scope="col">APLICAÇÃO</th>
                    <th class="text-center text-white" scope="col">SKU VW</th>
                    <th class="text-center text-white" scope="col">DESCRIÇÃO</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-M-E7Q</td>
                    <td class="text-center">FRISO BX RELEV VIRTUS -- PRATA SIRIUS</td>
                  </tr>
                  <tr>
                    <td class="text-center"> Acessórios</td>
                    <td class="text-center">V04-010-057-N-041</td>
                    <td class="text-center">FRISO BX RELEV NIVUS -- PRETO NINJA</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-N-A3X</td>
                    <td class="text-center">FRISO BX RELEV NIVUS -- VERMELHO SUNSET</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-N-A7C</td>
                    <td class="text-center">FRISO BX RELEV NIVUS -- CINZA MOONSTONE</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-N-B9A</td>
                    <td class="text-center">FRISO BX RELEV NIVUS -- BRANCO CRISTAL</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-N-C5C</td>
                    <td class="text-center">FRISO BX RELEV NIVUS -- AZUL BISCAY</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-N-D7X</td>
                    <td class="text-center">FRISO BX RELEV NIVUS -- CINZA PLATINUM</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-N-D5C</td>
                    <td class="text-center">FRISO BX RELEV NIVUS -- CORNFLOWER BLUE</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-N-T5C</td>
                    <td class="text-center">FRISO BX RELEV NIVUS -- TITAN BLUE</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-P-B7S</td>
                    <td class="text-center">FRISO BX RELEV TAOS-- PRATA PYRIT</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-P-C5B</td>
                    <td class="text-center">FRISO BX RELEV TAOS-- AZUL ATLANTIC</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-P-C9A</td>
                    <td class="text-center">FRISO BX RELEV TAOS-- BRANCO PURO</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-P-C9X</td>
                    <td class="text-center">FRISO BX RELEV TAOS-- PRETO MYSTIC</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-P-H1X</td>
                    <td class="text-center">FRISO BX RELEV TAOS-- BEGE MAJOWE</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-P-R7H</td>
                    <td class="text-center">FRISO BX RELEV TAOS-- CINZA INDIUM</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-Q-B7S</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- PRATA PYRIT</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-Q-B9A</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- BRANCO CRISTAL</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-Q-C5B</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- AZUL ATLANTIC</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-Q-C9X</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- PRETO MYSTIC</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-Q-R7H</td>
                    <td class="text-center">FRISO BX RELEV AMAROK -- CINZA INDIUM</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-080-AP</td>
                    <td class="text-center">MEIA DE PROTEÇÃO</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-011-035-J-041</td>
                    <td class="text-center">FRISO BX RELEV POLO PA -- PRETO NINJA </td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-010-057-L-A3X</td>
                    <td class="text-center">FRISO BX RELEV POLO PA -- VERMELHO SUNSET</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-011-035-J-B9A</td>
                    <td class="text-center">FRISO BX RELEV POLO PA -- BRANCO CRISTAL</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-011-035-J-D7X</td>
                    <td class="text-center">FRISO BX RELEV POLO PA -- CINZA PLATINUM</td>
                  </tr>
                  <tr>
                    <td class="text-center">Acessórios</td>
                    <td class="text-center">V04-011-035-J-E7Q</td>
                    <td class="text-center">FRISO BX RELEV POLO PA -- PRATA SIRIUS</td>
                  </tr>
                  </tbody>
                </table>

<!--
                <div class="container mt-5">
                  <div class="column bg-gray">
                    <div class="w-100 d-flex justify-content-center align-items-center mb-4 flex-column px-5">
                      <h2 class="text-primary"><strong>Itens sugeridos com pontuação</strong></h2>
                    </div>
                    <div class="w-100 d-flex justify-content-center align-items-center px-5">
                      <div class="w-70">
                        <div class="table-responsive">
                          <table class="table brazzo">
                            <thead class="thead-dark">
                            <tr>
                              <th class="text-secondary" scope="col">Código</th>
                              <th style="text-transform: unset" scope="col">Descrição</th>
                              <th style="text-transform: unset" scope="col" >Preço revenda</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>V04-010-024-C</td>
                              <td>Assento de transporte - pequeno</td>
                              <td>R$ 238,37</td>
                            </tr>

                            <tr>
                              <td>V04-010-024-D</td>
                              <td>Assento de transporte - grande</td>
                              <td>R$ 282,12</td>
                            </tr>
                            <tr>
                              <td>V04-010-024-E</td>
                              <td>Bolsa de transporte</td>
                              <td>R$ 283,86</td>
                            </tr>
                            <tr>
                              <td >V04-010-024-F</td>
                              <td >Caixa de transporte Nº 01</td>
                              <td>R$ 72,00</td>
                            </tr>
                            <tr>
                              <td>V04-010-024-G</td>
                              <td>Caixa de transporte Nº 02</td>
                              <td>R$ 90,31</td>
                            </tr>
                            <tr>
                              <td>V04-010-024-H</td>
                              <td>Caixa de transporte Nº 03</td>
                              <td>R$ 130,93</td>
                            </tr>
                            <tr>
                              <td>V04-010-024-J</td>
                              <td>Caixa de transporte Nº 04</td>
                              <td>R$ 177,23</td>
                            </tr>
                            &lt;!&ndash; &ndash;&gt;
                            <tr>
                              <td>V04-010-025-H</td>
                              <td>Adaptador de cinto de segurança (P)</td>
                              <td>R$ 25,74</td>
                            </tr>
                            <tr>
                              <td>V04-010-025-J</td>
                              <td>Adaptador de cinto de segurança (G)</td>
                              <td>R$ 35,33</td>
                            </tr>
                            <tr>
                              <td>V04-010-025-K</td>
                              <td>Coleira Peitoral</td>
                              <td>R$ 115,34</td>
                            </tr>
                            <tr>
                              <td>V04-010-026-A</td>
                              <td>Protetor de banco Basic</td>
                              <td>R$ 223,06</td>
                            </tr>
                            <tr>
                              <td>V04-010-026-B</td>
                              <td>Protetor de banco Standard</td>
                              <td>R$ 241,67</td>
                            </tr>
                            <tr>
                              <td>V04-010-026-C</td>
                              <td>Protetor de banco Premium</td>
                              <td>R$ 304,71</td>
                            </tr>
                            <tr>
                              <td>V04-010-029-K</td>
                              <td>Rede Proteção (P)</td>
                              <td>R$ 53,27</td>
                            </tr>
                            <tr>
                              <td>V04-010-029-L</td>
                              <td>Rede de Proteção com bolsos</td>
                              <td>R$ 283,86</td>
                            </tr>
                            <tr>
                              <td>V04-010-029-M</td>
                              <td>Rede Proteção (G)</td>
                              <td>R$ 125,50</td>
                            </tr>
                            <tr>
                              <td>V04-010-080-AP</td>
                              <td>Meia de Proteção</td>
                              <td>R$ 16,39</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -041</td>
                              <td>Friso Bx Relev T-Cross - Preto Ninja</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -A3X</td>
                              <td>Friso Bx Relev T-Cross - Vermelho Sunset</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -B7S</td>
                              <td>Friso bx relev T-Cross - Prata Pyrit</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -C9A</td>
                              <td>Friso bx relev T-Cross - Branco Puro</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -D7X</td>
                              <td>Friso bx relev T-Cross - Cinza Platinum</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -E5M</td>
                              <td>Friso bx relev T-Cross - Azul Norway</td>
                              <td>R$ 256,49</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="container mt-5">
                  <div class="column bg-gray">
                    <div class="w-100 d-flex justify-content-center align-items-center px-5">
                      <div class="w-70">
                        <div class="table-responsive">
                          <table class="table brazzo">
                            <thead class="thead-dark">
                              <tr>
                              <th class="text-secondary" scope="col">Código</th>
                              <th style="text-transform: unset" class="" scope="col">Descrição
                              </th>
                              <th style="text-transform: unset" scope="col" class="">Preço revenda</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>V04-010-024-C</td>
                              <td>Friso bx relev Virtus - Branco Cristal</td>
                              <td>R$ 256,49</td>
                            </tr>

                            <tr>
                              <td>V04-010-024-D</td>
                              <td>Assento de transporte - grande</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-024-F</td>
                              <td>Friso bx relev Virtus - Cinza Platinum</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-024-G</td>
                              <td>Friso bx relev Virtus - Prata Sirius</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-024-H</td>
                              <td>Friso bx relev Taos - Prata Pyrit</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-024-J</td>
                              <td>Friso bx relev Taos - Azul Atlantic</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-025-H</td>
                              <td>Friso bx relev Taos - Branco Puro</td>
                              <td>R$ 256,49</td>
                            </tr>
                            &lt;!&ndash; &ndash;&gt;
                            <tr>
                              <td>V04-010-025-J</td>
                              <td>Friso bx relev Taos - Preto Mystic</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-025-K</td>
                              <td>Friso bx relev Taos - Bege Majowe</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-026-A</td>
                              <td>Friso bx relev taos - Cinza Indium</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-026-B</td>
                              <td>Friso bx relev Amarok - Prata Pyrit</td>
                              <td>R$ 308,63</td>
                            </tr>
                            <tr>
                              <td>V04-010-026-B</td>
                              <td>Protetor de banco Standard</td>
                              <td>R$ 241,67</td>
                            </tr>
                            <tr>
                              <td>V04-010-026-C</td>
                              <td>Friso bx relev Amarok - Branco Cristal</td>
                              <td>R$ 308,63</td>
                            </tr>
                            <tr>
                              <td>V04-010-029-K</td>
                              <td>Friso bx relev Amarok - Azul Atlantic</td>
                              <td>R$ 308,63</td>
                            </tr>
                            <tr>
                              <td>V04-010-029-L</td>
                              <td>Friso bx relev Amarok - Preto Mystic</td>
                              <td>R$ 308,63</td>
                            </tr>
                            <tr>
                              <td>V04-010-029-M</td>
                              <td>Friso bx relev Amarok - Cinza Indium</td>
                              <td>R$ 308,63</td>
                            </tr>
                            <tr>
                              <td>V04-010-080-AP</td>
                              <td>Friso bx relev Polo pa - Vermelho Sunset</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -041</td>
                              <td>Friso bx relev Polo pa - Branco Cristal</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -A3X</td>
                              <td>Friso bx relev Polo pa - Cinza Platinum</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -B7S</td>
                              <td>Friso bx relev Polo pa - Prata Sirius</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -C9A</td>
                              <td>Friso bx relev Polo pa - Preto Ninja</td>
                              <td>R$ 256,49</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -D7X</td>
                              <td>Agente de Limpeza EA 211</td>
                              <td>R$ 24,87</td>
                            </tr>
                            <tr>
                              <td>V04-010-057-L -E5M</td>
                              <td>Friso bx relev T-Cross - Azul Norway</td>
                              <td>R$ 256,49</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>-->


              </div>
            </div>
          </div>
        </div>
      </section>
    </mat-tab>
    <!--<mat-tab label="Bosch">
      <section class="bosch position-relative">
        <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
        <img class="triangle-1" src="assets/images/partner/triangle2.png" alt="triangle">
        <div class="container awards_secondary py-5">

          <div class="row">
            <div class="col-sm-4 d-flex">
              <div class="d-flex logo-card">
                <img src="assets/images/partner/bosch.png" alt="maxi">
              </div>
            </div>
          </div>

          <div class="row col-12 d-flex justify-content-between">
            <div class="col-lg-4 col-12 row-descritive">
              <p>
                A pontuação mensal terá como base o percentual comparativo entre o valor dos pedidos colocados no mês,
                dos produtos BOSCH avaliados na campanha, e o valor do volume objetivado desses mesmos produtos para a
                concessionária, com base no Sistema ASAS (Controle de Performance de Peças e Acessórios).
              </p>
              <p class="tirreno_subtitle text-primary mt-4">
                Os pontos passam a ser atribuídos numa escala crescente a partir do atingimento de 90% do objetivo BOSCH
                pré-estabelecido para cada concessionária, conforme tabela ao lado:
              </p>
            </div>
            <div class="col-lg-6 col-12 row-descritive">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-dark">
                  <tr>
                    <th class=" w-70 text-secondary" scope="col">Percentual de cumprimento do objetivo</th>
                    <th class="text-primary text-center w-30" scope="col">Pontos</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="text-primary text-center w-70">De 0,00% a 89,99%</td>
                    <td class=" bg-g text-center w-30">0</td>
                  </tr>
                  <tr>
                    <td class=" text-center w-70">90% a 109,99%</td>
                    <td class=" bg-g text-center w-30">80</td>
                  </tr>
                  <tr>
                    <td class="text-center w-70">110% a 119,99%</td>
                    <td class=" bg-g text-center w-30">140</td>
                  </tr>
                  <tr>
                    <td class="text-center w-70">Acima de 120%</td>
                    <td class="bg-g text-center w-30">180</td>
                  </tr>
                  <tr class="bg-secondary">
                    <td class="text-center w-70">Bônus*</td>
                    <td class="text-center w-30">20</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="column bg-gray">
              <div class="w-100 d-flex justify-content-center align-items-center mb-4 flex-column">
                <h2 class="text-primary"><strong>Produtos avaliados na Campanha</strong></h2>
                <span class="text-primary">As concessionárias serão pontuadas durante a campanha na compra dos seguintes produtos:</span>
              </div>
              <div class="w-100 d-flex justify-content-center align-items-center">
                <div class="w-50">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-dark">
                      <tr>
                        <th class="w-30 text-secondary " scope="col">Código</th>
                        <th style="text-transform: unset" class="w-70  " scope="col">Descrição
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>APR-096-153</td>
                        <td>Espuma de Limpeza à seco</td>
                      </tr>

                      <tr>
                        <td>G-001-780-M3</td>
                        <td>New AW-AG2000 - Aditivo de Combustível - Flex</td>
                      </tr>
                      <tr>
                        <td>G-JZW-012-R2</td>
                        <td>Aditivo - Líquido de Refrigeração</td>
                      </tr>
                      <tr>
                        <td>STP-ST0-912</td>
                        <td>Throttle Body & Air Intake Cleaner</td>
                      </tr>
                      <tr>
                        <td>G-001-780-M3</td>
                        <td>New AW-AG2000 - Aditivo de Combustível - Flex</td>
                      </tr>
                      <tr>
                        <td>G-JZW-012-R2</td>
                        <td>Aditivo - Líquido de Refrigeração</td>
                      </tr>
                      <tr>
                        <td>STP-ST0-912</td>
                        <td>Throttle Body & Air Intake Cleaner</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="margin: 50px 0" class="container">
            <div class="column bg-gray">
              <div class="w-100 d-flex justify-content-center align-items-center mb-4">
                <h2><strong class="text-primary">Linha Economy | </strong> <strong class="text-secondary">NOVO</strong></h2>
              </div>
              <div class="w-100 d-flex justify-content-center align-items-center">
                <div class="w-50">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-dark">
                      <tr>
                        <th class="w-30 text-secondary " scope="col">Código</th>
                        <th style="text-transform: unset" class="w-70  " scope="col">Descrição
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>APR-096-153</td>
                        <td>Espuma de Limpeza à seco</td>
                      </tr>

                      <tr>
                        <td>G-001-780-M3</td>
                        <td>New AW-AG2000 - Aditivo de Combustível - Flex</td>
                      </tr>
                      <tr>
                        <td>G-JZW-012-R2</td>
                        <td>Aditivo - Líquido de Refrigeração</td>
                      </tr>
                      <tr>
                        <td>STP-ST0-912</td>
                        <td>Throttle Body & Air Intake Cleaner</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="column bg-gray">
              <div class="w-100 d-flex justify-content-center align-items-center mb-4">
                <h2><strong class="text-primary">Acessórios | </strong> <strong class="text-secondary">NOVO</strong></h2>
              </div>
              <div class="w-100 d-flex justify-content-center align-items-center">
                <div class="w-50">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-dark">
                      <tr>
                        <th class="w-30 text-secondary " scope="col">Código</th>
                        <th style="text-transform: unset" class="w-70  " scope="col">Descrição
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>APR-096-153</td>
                        <td>Espuma de Limpeza à seco</td>
                      </tr>

                      <tr>
                        <td>G-001-780-M3</td>
                        <td>New AW-AG2000 - Aditivo de Combustível - Flex</td>
                      </tr>
                      <tr>
                        <td>G-JZW-012-R2</td>
                        <td>Aditivo - Líquido de Refrigeração</td>
                      </tr>
                      <tr>
                        <td>STP-ST0-912</td>
                        <td>Throttle Body & Air Intake Cleaner</td>
                      </tr>
                      <tr>
                        <td>G-001-780-M3</td>
                        <td>New AW-AG2000 - Aditivo de Combustível - Flex</td>
                      </tr>
                      <tr>
                        <td>G-JZW-012-R2</td>
                        <td>Aditivo - Líquido de Refrigeração</td>
                      </tr>
                      <tr>
                        <td>STP-ST0-912</td>
                        <td>Throttle Body & Air Intake Cleaner</td>
                      </tr>
                      <tr>
                        <td>G-001-780-M3</td>
                        <td>New AW-AG2000 - Aditivo de Combustível - Flex</td>
                      </tr>
                      <tr>
                        <td>G-JZW-012-R2</td>
                        <td>Aditivo - Líquido de Refrigeração</td>
                      </tr>
                      <tr>
                        <td>STP-ST0-912</td>
                        <td>Throttle Body & Air Intake Cleaner</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </mat-tab>-->
  </mat-tab-group>


</div>
